/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import styled from 'styled-components'
import facebookIcon from '../images/facebook.svg';
import instagramIcon from '../images/instagram.svg';
import PropTypes from "prop-types"

import "./layout.css"

const Footer = styled.footer`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 margin: 100px 0 30px 0;
 
 p {
  font-size: 16px;
  color: #124963;
  margin: 0;
  text-align: center;
 }
 
 a {
  color: #124963;
 }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 10px;
  
  a {
   margin: 5px;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <Footer>
          <p>
            επικοινωνία : <a href="tel:00302815305005">+30 2815 305005</a>,{' '}
            <a href="https://g.page/Greenbees?share" target="_blank" rel="noreferrer" >Πριάνσου 8 Ηράκλειο Κρήτης</a>,{' '}
            <a href="mailto:info@green-bees.gr">info@green-bees.gr</a>{' '}
          </p>
          <SocialContainer>
            <a href="https://www.facebook.com/Greenbees-%CE%9C%CE%B7%CF%87%CE%B1%CE%BD%CE%B9%CE%BA%CE%BF%CE%AF-508304055901005" target="_blank" rel="noreferrer">
              <img width="20px" alt="facebook" src={facebookIcon}/>
            </a>
            <a href="https://www.instagram.com/greenbeesgr/" target="_blank" rel="noreferrer">
              <img width="20px" alt="facebook" src={instagramIcon}/>
            </a>
          </SocialContainer>
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
